import { useState } from 'react';
import React from 'react';
import './MatchComparisonBar.scss';

type MatchComparisonBarProps = {
    score: number;
    allScores: number[];
    tooltipFormatter?: (score: number) => string;
};

const MatchComparisonBar = ({ 
    score, 
    allScores,
    tooltipFormatter = (score) => `Match Score: ${(score * 100).toFixed(0)}%`
}: MatchComparisonBarProps) => {
    const [activeTooltip, setActiveTooltip] = useState(null);

    if (!allScores || allScores.length === 0) {
        return null;
    }

    const sortedScores = [...allScores].sort((a, b) => a - b);
    const maxScore = Math.max(...allScores);
    const minScore = Math.min(...allScores);

    // Places lower scores on left, higher scores on right
    const normalizeValue = (value: number) => {
        if (maxScore === minScore) return 0;
        return ((value - minScore) / (maxScore - minScore) * 100);
    };

    const otherScores = allScores.filter(s => s !== score);

    return (
        <div className="matchComparisonBarContainer">
            <h3 className="utilityBarTitle">Match Comparison:</h3>
            <p className="subTitle">How this option compared to the other results found</p>
            
            <div className="utilityBar">
                {otherScores.map((otherScore, index) => {
                    const left = `${normalizeValue(otherScore)}%`;
                    return (
                        <div 
                            className="dotContainer" 
                            key={index} 
                            style={{ left }}
                        >
                            <div
                                className="dot"
                                onMouseEnter={() => setActiveTooltip(index)}
                                onMouseLeave={() => setActiveTooltip(null)}
                            />
                            {activeTooltip === index && (
                                <div className="scoreTooltip">
                                    <div className="scoreValue">
                                        {tooltipFormatter(otherScore)}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}

                <div 
                    className="dotContainer" 
                    style={{ left: `${normalizeValue(score)}%` }}
                >
                    <div
                        className="dot highlighted"
                        onMouseEnter={() => setActiveTooltip(allScores.length)}
                        onMouseLeave={() => setActiveTooltip(null)}
                    />
                    {activeTooltip === allScores.length && (
                        <div className="scoreTooltip">
                            <div className="scoreValue">
                                {tooltipFormatter(score)}
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="labelContainer">
                <span className="label">Lower Match</span>
                <span className="label">Better Match</span>
            </div>
        </div>
    );
};

export default MatchComparisonBar;